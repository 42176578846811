import React from 'react'
import { withCookies } from "react-cookie";
import t from './../lib/translate'

class CookieInfoPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alreadyClicked: this.userAlreadyClicked()
    }
  }

  userAlreadyClicked() {
    return this.props.cookies.get('understood', { path: "/" });
  }

  handleUnderstoodClick() {
    this.props.cookies.set('understood', 'true', { path: "/" });
    this.setState({alreadyClicked: 'true'})
  }

  render() {
    if (this.state.alreadyClicked) {
      return ''
    }
    return (
      <div className="fixed px-4 py-5 lg:px-32 lg:py-10 bottom-0 left-0 z-50 bg-dark-green w-full">
        <h4 className="uppercase mb-4 text-white text-2xl">Cookie settings</h4>
        <div className="block lg:flex space-between text-white text-base">
          <div className="lg:w-1/2 mb-5 lg:mb-0">
            <p>
              {t().cookie_panel.content}
            </p>
            <a href="#" className="text-lime underline">{t().cookie_panel.read_more}</a>
          </div>
          <div className="block lg:flex justify-end lg:w-1/2 mb-20 lg:mb-0">
              <button className="button btn btn-primary uppercase w-full lg:w-44 text-lg" type="button" onClick={() => this.handleUnderstoodClick() }>
                {t().cookie_panel.agree_button}
              </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withCookies(CookieInfoPanel)
