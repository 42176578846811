import React from 'react'
import {initJsPsych} from 'jspsych'
import jsPsychSurveyHtmlForm from '@jspsych/plugin-survey-html-form'
import ProgressBar, { setTrialProgressBar } from './ProgressBar'
import t from './../lib/translate'

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTrial: 1
    }

    this.jsPsych = initJsPsych({
      show_progress_bar: false,
      auto_update_progress_bar: true,
      display_element: 'questions_content',
      on_trial_start: (trialData) => {
        this.forceUpdate(() => {
          setTimeout(() => {
            document.querySelectorAll(`[type=text][name=${trialData.name}_text]`).forEach((element) => {
              element.disabled = true
            })
            document.querySelectorAll(`[type=checkbox][name=${trialData.name}], [type=radio][name=${trialData.name}]`).forEach((element) => {
              element.addEventListener(
                'change',
                (e) => {
                  if (e.target.checked) {
                    let label = e.target.nextElementSibling
                    if (label.children.length > 0) {
                      label.children[1].disabled = false;
                    }
                  }
                  document.querySelectorAll(`[type=text][name=${trialData.name}_text]`).forEach((element) => {
                    if (!element.parentElement.previousElementSibling.checked) {
                      element.disabled = true
                      element.value = ''
                    }
                  })
                }
              );
            })
          }, 1);

          if (trialData.question_type == 'MultiSelectQuestion' && trialData.max_answer_count && trialData.max_answer_count > 0) {
            // could not find better way, how to do it, elements are not visible after forceUpdate
            setTimeout(() => {
              document.querySelectorAll(`[type=checkbox][name=${trialData.name}]`).forEach((element) => {
                element.addEventListener(
                  'change',
                  (e) => {
                    if (document.querySelectorAll(`[name=${trialData.name}]:checked`).length > trialData.max_answer_count) {
                      e.target.checked = false
                      e.preventDefault()
                      return
                    }
                  }
                );
              })
            }, 1);
          }
        })
      },
      on_trial_finish: (trialData) => {
        this.setState({currentTrial: trialData.trial_index + 2})
        this.props.updateProgress()
      },
      on_finish: () => {
        let responses = []
        let previousResponse = null
        this.jsPsych.data.allData.trials.map((trial) => trial.response).flat().map((response) => {
          if (response && response.name && response.value) {
            let id = response.name.replace('question', '')
            if (!response.name.includes('_text')) {
              previousResponse = {id: id, value: response.value}
              responses.push(previousResponse)
            } else {
              id = id.replace('_text', '')
              if (previousResponse) {
                previousResponse.text_value = response.value
              }
            }
          }
        })
        this.props.onQuestionnaireFinished(responses.filter((response) => response))
      }
    })
  }

  initTrials(trials) {
    trials = trials.map((trial, index) => {
      trial.type = jsPsychSurveyHtmlForm
      setTrialProgressBar(trial)
      return trial
    })
    this.jsPsych.run(trials);
  }

  componentDidMount() {
    this.initTrials(this.props.data.trials)
  }

  getValue() {
    let trial = this.jsPsych.getCurrentTrial()
    let input
    if (trial.question_type && trial.question_type.includes('Select')) {
      input = document.querySelector(`[name=${trial.name}]:checked`)
      return !!input
    } else {
      input = document.getElementsByName(trial.name)[0]
      return input.value
    }
  }

  focusQuestionnaireTop() {
    document.getElementById("questionnaire").scrollIntoView();
  }

  submitResponse() {
    let value = this.getValue()
    if (value && value != "") {
      this.setState({error: false})
      document.querySelector("#jspsych-survey-html-form-next").click()
    } else {
      this.setState({error: true})
    }
    this.focusQuestionnaireTop()
  }

  skipResponse() {
    this.setState({error: false})
    this.focusQuestionnaireTop()
    this.jsPsych.finishTrial()
  }

  render() {
    return (
      <div className="relative" id="questionnaire">
        {
          this.state.error ?
            <div className="bg-cherry2 flex items-center text-white absolute w-full px-5 py-4">
              <div className="bg-warning block w-8 h-8 mr-6"></div>
              {t().questionnaire.enter_all_questions}
            </div>
          : null
        }
        <h1 className="text-xl lg:text-4xl lg:mb-20 mb-16 font-bold">{this.props.data.name}</h1>
        <ProgressBar jsPsych={this.jsPsych} totalPages={this.props.data.trials.length} currentProgress={this.props.currentProgress} totalProgress={this.props.totalProgress} />
        <div className="my-10">
          {
            (this.state.currentTrial == 1) ?
              <p>{t().questionnaire.description}</p>
            : null
          }
        </div>
        <div id="questions_content" className="w-full border-0 mb-14">
        </div>
        <div className="flex flex-col lg:flex-row mb-10">
          <div className="w-full lg:w-1/3 flex justify-center lg:justify-start">
          </div>
          <div className="flex justify-center w-full lg:w-1/3">
            <button className="btn btn-primary w-full lg:w-auto" onClick={this.submitResponse.bind(this)}>
              {t().questionnaire.submit}
            </button>
          </div>
          <div className="flex justify-center lg:justify-end w-full lg:w-1/3 w-full lg:w-auto">
            <button className="btn w-full lg:w-60 btn-skip" onClick={this.skipResponse.bind(this)}>
            {t().questionnaire.skip}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Questionnaire