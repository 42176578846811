import React, { useState } from "react";
import t from './../lib/translate'

const PrivacyAcknowledgement = (props) => {
  return (
    <>
      <h1 className="text-xl lg:text-4xl mb-16">{t().privacy_acknowledgement.headline}</h1>

      <h2 className="text-xl mb-2">{t().privacy_acknowledgement.data_privacy_headline}</h2>
      <div className="mb-10" dangerouslySetInnerHTML={{__html: t().privacy_acknowledgement.data_privacy_content}}></div>

      <h2 className="text-xl mb-2">{t().privacy_acknowledgement.iat_test_headline}</h2>
      <div className="mb-10" dangerouslySetInnerHTML={{__html:t().privacy_acknowledgement.iat_test_content}}></div>

      <h2 className="text-xl mb-2">{t().privacy_acknowledgement.newsletter_headline}</h2>
      <div className="mb-10" dangerouslySetInnerHTML={{__html:t().privacy_acknowledgement.newsletter_content}}></div>

      <h2 className="text-xl mb-2">{t().privacy_acknowledgement.contact_headline}</h2>
      <div className="mb-10" dangerouslySetInnerHTML={{__html:t().privacy_acknowledgement.contact_content}}></div>

      <div className="flex flex-col lg:flex-row mb-10">
          <div className="w-full lg:w-1/3 flex justify-center lg:justify-start">
          </div>
          <div className="flex justify-center w-full lg:w-1/3">
            <button className="btn btn-primary w-full lg:w-auto" onClick={() => props.nextStep() }>{t().privacy_acknowledgement.agree_button}</button>
          </div>
          <div className="flex justify-center lg:justify-end w-full lg:w-1/3 w-full lg:w-auto text-center">
            <a href={props.declinedUrl} className="btn w-full lg:w-60 btn-skip">{t().privacy_acknowledgement.decline_button}</a>
          </div>
      </div>
    </>
  );
};

export default PrivacyAcknowledgement;
