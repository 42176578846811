import React, { useState } from "react";
import t from './../lib/translate'

const AnswerOption = (props) => {
  return (
    <div className={`smile-button ${props.className ? props.className : ''}`} onClick={props.onClick}>
      <div className="ml-1">{props.answer.text}</div>
    </div>
  );
};

const FeedbackQuestion = (props) => {

  const [answered, setAnswered] = useState(false);

  const sendAnswer = (answer) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    fetch(answer.url, requestOptions)
      .then(response => response.json())
      .then(data => {
        setAnswered(true)
      });
  }

  return (
    <div>
      {(props.answered || answered) ?
      <div className="bg-dark-green p-2 pl-28 radius-xl" id="feedback_question">
        <div className="flex">
          <div className="w-1/4">
            <img src={props.happyFacePath} alt="smiling face" className="smile-animation-image" />
          </div>
          <div className="w-3/4 flex items-center justify-center">
            <div className="text-2xl text-white max-w-md">
              {t().feedback_question.sentence}
            </div>
          </div>
        </div>
      </div>
        : <div className="bg-dark-green p-20 radius-xl" id="feedback_question">
            <div className="flex justify-center">
              <h2 className="font-bold text-2xl mb-10 text-white">{props.questionText}</h2>
            </div>
            <div className="flex justify-center">
              <AnswerOption answer={props.answers[0]} onClick={() => sendAnswer(props.answers[0])} />
              <AnswerOption answer={props.answers[1]} onClick={() => sendAnswer(props.answers[1])} className="ml-5" />
            </div>
          </div>
      }
    </div>
  )
}

export default FeedbackQuestion;