import React from 'react'

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.jsPsych = props.jsPsych
  }

  componentDidMount() {
  }

  render() {
    let currentTrial = this.jsPsych.getCurrentTrial()

    if (!currentTrial.progress_bar || !this.jsPsych) {
      return ''
    }
    let percent = ((this.props.currentProgress) / (this.props.totalProgress + 1)) * 100

    return (
      <div className="w-full uppercase"
        role="progressbar" aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100" aria-label="progress">
        <div className="w-full bg-lime mt-8">
          <div className="h-1 bg-dark-green relative" style={{width: `${percent}%`}}>
            <div className="bubble">
              {percent.toFixed(0)}%
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const setTrialProgressBar = (trial) => {
  trial.progress_bar = true
}
export default ProgressBar